
    const handleScrollToPricing = (scrollSection, event) => {
        document.getElementById('nav-toggle')?.click();
        const pricingSection = document.getElementById(scrollSection);
        if (pricingSection) {
          pricingSection.scrollIntoView({ behavior: 'smooth' });
          console.log('smooth');
        }
        event.preventDefault();
      };

export default handleScrollToPricing