import React from 'react';
import cambriLogo2 from '../../../src/icons/Cambri-blue.png';
import androidDownload from '../../../src/icons/android-card.svg'
import androidMobileDownload from '../../../src/icons/mobileAppIcon.svg'
import { Link } from 'react-router-dom';
const Footer = ({page}) => {
  React.useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    // Optionally, you can also add an event listener to handle route changes
    const handleRouteChange = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('popstate', handleRouteChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

    const ScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

  return (
    <div className="w-full h-full animate-fade-in px-4 lg:px-28 mt-10">
      <div className="bg-white w-auto  flex flex-col pt-8 md:pt-10 px-4 lg:px-0">
        <div className="flex flex-col items-center bg-lightgray bg-center bg-no-repeat bg-cover">
          <div
            style={{
              
              background:page !== 'ieltsPage' ?
                'linear-gradient(to right, rgba(254, 254, 254, 0.19) 0%, rgba(227, 24, 55, 0.038) 100%)':"",
              border:page !== 'ieltsPage' ?  '2px solid rgba(160, 51, 255, 0.40)':"",
            }}
            className={`text-center lg:px-[50px] px-4 py-12 md:rounded-[50px] rounded-[20px] w-full flex flex-col items-center justify-center ${page=='ieltsPage' ? 
            "bg-white border-2 border-[#E31837]":""}`}
          >
            {
              page!="ieltsPage" ? <h1 className="lg:text-[54px] md:text-3xl text-xl font-semibold font-avenir">
              <span className="text-[#650BE0] md:leading-[70px] leading-[0px] ">{window.location.pathname === '/sat' ? "Check out Cambri" : "Check out how Cambri"} </span>
              <br />
              <span className="text-black inline-block leading-8 mt-4 lg:leading-[70px] mb-[40px]">Simplify and Accelerate your College Admissions</span>
            </h1>:<h1 className="lg:text-[54px] md:text-3xl text-xl font-semibold font-avenir ">
            <span className="text-black md:leading-[70px] leading-[0px] mb-[40px]">
              Prepare smartly and efficiently with our feature-packed{' '}
            </span>
            <span className={`${page==="ieltsPage" ?"text-[#E32D49]" : "text-[#650BE0]"}  inline-block leading-[70px] `}>
              IELTS APP
            </span>
          </h1>}
            {page!="ieltsPage" ? <button
              style={{ background: 'linear-gradient(152deg, #8431FC 0%, #A632EB 100%) ' }}
              className="rounded-[40px] text-white py-2 px-4 w-full sm:w-[284px] h-10 lg:h-[64px] lg:text-2xl text-base font-semibold leading-normal font-avenir"
              onClick={() => window.open('https://app.cambri.ai/signupnew','_blank', 'noopener')}
            >
             Get started for free
            </button> : <div className="sm:flex cursor-pointer">
              <button onClick={()=>{
                  window.open("https://play.google.com/store/apps/details?id=com.cambri.ielts",'_blank', 'noopener');
            }}>
              <img src={androidDownload} alt="android download img" />
              </button>
            <button onClick={()=>{
                  window.open("https://apps.apple.com/in/app/cambri-ielts-speaking-tests/id6466289496", '_blank', 'noopener');
            }}><img src={androidMobileDownload} alt="android download img" /></button>
            
          </div>}
          </div>
          <img className="md:mt-28 mt-16 mb-20 mx-auto" src={cambriLogo2} alt='cambriLogo2' />
        </div>

        <div className="flex sm:justify-center px-2 lg:px-0 md:gap-x-32 gap-x-14 gap-y-20 text-[#737E93] flex-wrap md:text-xl text-sm">
          <div className='flex flex-col'>
          <p className="text-[#131313] md:text-[28px] text-lg font-extrabold mb-7">CONTACT</p>
          <p className="text-[#737E93] text-sm w-[70%] md:text-base text-[0.7rem]">
                Cambri Private Limited{" "}
              </p>
              <p className="text-[#737E93] text-sm w-[70%] md:text-base text-[0.7rem]">
              Suite #302, 4th Floor,
              </p>
              <p className="text-[#737E93] text-sm w-[70%] md:text-base text-[0.7rem]">
              Green Domain Layout,
              </p>
              <p className="text-[#737E93] text-sm w-[90%] md:text-base text-[0.7rem]">
              7th Cross Kundalahalli Main Road,
              </p>
              <p className="text-[#737E93] text-sm w-[70%] md:text-base text-[0.7rem]">
                Bangalore - 560 048, Karnataka. India.{" "}
              </p>
              <p className="flex text-[#737E93] text-sm  md:text-base items-center">
                support@cambri.ai
              </p>
              <p className="flex  items-center">
                <p className="text-[#737E93] text-sm ">+91 75699 83722, +1 (571) 699-6196</p>
              </p>
          </div>
          <div className='flex flex-col'>
            <p className="text-[#131313] md:text-[28px] text-lg font-extrabold mb-7">Cambri</p>
            <Link to="/" className="mb-7" onClick={ScrollToTop}>How it works</Link>
            {(window.location.pathname !=='/sat')&&
            <button className='cursor-pointer' onClick={()=>{
              if(window.location.pathname.includes('/ielts')){
                window.open("https://web.cambri.ai/ieltspayments", '_blank', 'noopener');
              }
              else{
              window.open("https://web.cambri.ai/payments", '_blank', 'noopener');
              }
            }}>Pricing</button>}
          </div>
          <div className='flex flex-col'>
            <p className="text-[#131313] md:text-[28px] text-lg font-extrabold mb-7">Products</p>           
            <Link to = "/"  className="mb-7" onClick={ScrollToTop}>Statement of Purpose</Link>
            <Link to = "/ielts"  className="mb-7" >IELTS</Link>
            <Link to = "/sat" >SAT</Link>
          </div>
          <div className='flex flex-col'>
            <p className="text-[#131313] md:text-[28px] text-lg font-extrabold mb-7">Company</p>
            <Link to="/aboutus" className="mb-7">About Us</Link>
            <Link to="/contactus" className="mb-7">Contact Us</Link>
            {<Link to="/blogs">Blogs</Link>}
          </div>
        </div>
        <hr className='text-[#C7CBD4] mt-14 md:block hidden'/>
        <div className="flex flex-wrap-reverse md:flex-nowrap justify-center lg:justify-between w-full m-auto md:flex md:items-center pb-5 mt-8">

          <div className="text-center text-[#737E93] text-[10px] md:text-lg mt-5">
          <hr className='text-[#C7CBD4] mb-5 md:hidden block w-screen'/>

            <i className="fa-light fa-copyright "></i>{' '}
            <span>2023 Cambri, INC. All rights reserved</span>
          </div>
          <div className="flex items-center justify-center space-x-10 mt-4 lg:mt-[0] ">
            { <div className="flex space-x-6 mt-6 md:mt-0">
              <p className="md:text-lg text-xs text-[#737E93]"> <a href="https://app.cambri.ai/returnandrefundpolicy" target="_blank">Return and Refund Policy</a></p>
              <p className="md:text-lg text-xs text-[#737E93]"> <a href="https://app.cambri.ai/termsandconditions" target="_blank">Terms and Conditions</a></p>
              <p className="md:text-lg text-xs text-[#737E93]"> <a href="https://app.cambri.ai/PrivacyPolicy" target="_blank">Privacy Policy</a></p>
            </div> }
            <div className="flex mt-6 md:mt-0 ml-0 lg:space-x-6 xl:space-x-6 md:space-x-6  cursor-pointer">
              <div className='mr-3 md:mr-0'>
                <svg
                  onClick={() =>
                    window.open('https://www.linkedin.com/in/cambri-ai-for-students-78356327b', '_blank', 'noopener')
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  fill="none"
                  className='w-5 md:w-9 h-5 md:h-9'
                >
                  <g clipPath="url(#clip0_1704_3653)">
                    <path
                      d="M31.0909 0H4.90909C2.19787 0 0 2.19787 0 4.90909V31.0909C0 33.8021 2.19787 36 4.90909 36H31.0909C33.8021 36 36 33.8021 36 31.0909V4.90909C36 2.19787 33.8021 0 31.0909 0Z"
                      fill="#737E93"
                    />
                    <path
                      d="M12.9645 9.81745C12.9645 10.4243 12.7845 11.0175 12.4474 11.522C12.1103 12.0266 11.6311 12.4199 11.0705 12.6521C10.5098 12.8843 9.8929 12.9451 9.29774 12.8267C8.70257 12.7083 8.15587 12.4161 7.72678 11.987C7.29768 11.5579 7.00547 11.0112 6.88708 10.416C6.76869 9.82085 6.82945 9.20394 7.06168 8.64331C7.2939 8.08267 7.68716 7.60349 8.19172 7.26635C8.69628 6.92921 9.28948 6.74927 9.89631 6.74927C10.71 6.74927 11.4904 7.07252 12.0658 7.64792C12.6412 8.22331 12.9645 9.00372 12.9645 9.81745Z"
                      fill="white"
                    />
                    <path
                      d="M12.2693 14.9313V28.4865C12.2698 28.5865 12.2506 28.6857 12.2127 28.7782C12.1748 28.8708 12.119 28.9549 12.0485 29.0258C11.9779 29.0967 11.8941 29.153 11.8018 29.1914C11.7094 29.2297 11.6104 29.2495 11.5104 29.2495H8.27654C8.17654 29.2498 8.07747 29.2303 7.98503 29.1921C7.89259 29.154 7.8086 29.0979 7.73789 29.0272C7.66718 28.9565 7.61114 28.8725 7.573 28.7801C7.53486 28.6877 7.51536 28.5886 7.51563 28.4886V14.9313C7.51563 14.7295 7.5958 14.536 7.73849 14.3933C7.88119 14.2506 8.07473 14.1704 8.27654 14.1704H11.5104C11.7119 14.171 11.9049 14.2514 12.0471 14.394C12.1894 14.5366 12.2693 14.7299 12.2693 14.9313Z"
                      fill="white"
                    />
                    <path
                      d="M29.1761 21.9878V28.5496C29.1764 28.6416 29.1585 28.7326 29.1234 28.8176C29.0884 28.9026 29.0368 28.9799 28.9718 29.0449C28.9068 29.1099 28.8296 29.1614 28.7446 29.1965C28.6596 29.2315 28.5685 29.2494 28.4766 29.2492H24.9993C24.9074 29.2494 24.8163 29.2315 24.7313 29.1965C24.6463 29.1614 24.5691 29.1099 24.504 29.0449C24.439 28.9799 24.3875 28.9026 24.3524 28.8176C24.3174 28.7326 24.2995 28.6416 24.2997 28.5496V22.1903C24.2997 21.2412 24.5779 18.0339 21.8186 18.0339C19.6811 18.0339 19.2454 20.2287 19.1595 21.2146V28.5496C19.1595 28.7334 19.0872 28.9098 18.9583 29.0407C18.8293 29.1716 18.654 29.2465 18.4702 29.2492H15.1116C15.0198 29.2492 14.9289 29.2311 14.8442 29.1959C14.7594 29.1607 14.6824 29.1091 14.6176 29.0441C14.5528 28.9792 14.5015 28.902 14.4666 28.8172C14.4316 28.7323 14.4138 28.6414 14.4141 28.5496V14.8717C14.4138 14.7799 14.4316 14.689 14.4666 14.6041C14.5015 14.5192 14.5528 14.4421 14.6176 14.3771C14.6824 14.3121 14.7594 14.2606 14.8442 14.2254C14.9289 14.1902 15.0198 14.1721 15.1116 14.1721H18.4702C18.6557 14.1721 18.8337 14.2458 18.9649 14.377C19.096 14.5082 19.1697 14.6861 19.1697 14.8717V16.0539C19.9634 14.8635 21.1395 13.9451 23.6493 13.9451C29.2088 13.9451 29.1761 19.1364 29.1761 21.9878Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1704_3653">
                      <rect width="36" height="36" rx="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className='mr-3 md:mr-0'>
                <svg
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/profile.php?id=100093867785743&mibextid=LQQJ4d', '_blank', 'noopener'
                    )
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  fill="none"
                  className='w-5 md:w-9 h-5 md:h-9'
                >
                  <g clipPath="url(#clip0_1704_3661)">
                    <path
                      d="M31.5 0H4.5C2.01825 0 0 2.01825 0 4.5V31.5C0 33.9818 2.01825 36 4.5 36H31.5C33.9818 36 36 33.9818 36 31.5V4.5C36 2.01825 33.9818 0 31.5 0Z"
                      fill="#737E93"
                    />
                    <path
                      d="M30.375 18H24.75V13.5C24.75 12.258 25.758 12.375 27 12.375H29.25V6.75H24.75C21.0217 6.75 18 9.77175 18 13.5V18H13.5V23.625H18V36H24.75V23.625H28.125L30.375 18Z"
                      fill="#FAFAFA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1704_3661">
                      <rect width="36" height="36" rx="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className='mr-3 md:mr-0'>
                <svg
                  onClick={() =>
                    window.open('https://instagram.com/cambri_ai?igshid=Y2IzZGU1MTFhOQ==', '_blank', 'noopener')
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                  fill="none"
                  className='w-5 md:w-9 h-5 md:h-9'
                >
                  <g clipPath="url(#clip0_1704_3670)">
                    <path
                      d="M2.24566 2.44945C-0.58334 5.38795 -0.00433978 8.50945 -0.00433978 17.9925C-0.00433978 25.8675 -1.37834 33.762 5.81266 35.6205C8.05816 36.198 27.9542 36.198 30.1967 35.6175C33.1907 34.845 35.6267 32.4165 35.9597 28.182C36.0062 27.591 36.0062 8.40445 35.9582 7.80145C35.6042 3.29095 32.8277 0.691448 29.1692 0.164948C28.3307 0.0434475 28.1627 0.00744753 23.8607 -5.24707e-05C8.60116 0.00744753 5.25616 -0.672053 2.24566 2.44945Z"
                      fill="#737E93"
                    />
                    <path
                      d="M17.9928 4.70863C12.5463 4.70863 7.37432 4.22413 5.39882 9.29413C4.58282 11.3881 4.70132 14.1076 4.70132 18.0016C4.70132 21.4186 4.59182 24.6301 5.39882 26.7076C7.36982 31.7806 12.5838 31.2946 17.9898 31.2946C23.2053 31.2946 28.5828 31.8376 30.5823 26.7076C31.3998 24.5926 31.2798 21.9136 31.2798 18.0016C31.2798 12.8086 31.5663 9.45613 29.0478 6.93913C26.4978 4.38913 23.0493 4.70863 17.9868 4.70863H17.9928ZM16.8018 7.10413C28.1628 7.08613 29.6088 5.82313 28.8108 23.3686C28.5273 29.5741 23.8023 28.8931 17.9943 28.8931C7.40431 28.8931 7.09982 28.5901 7.09982 17.9956C7.09982 7.27813 7.93982 7.11013 16.8018 7.10113V7.10413ZM25.0878 9.31063C24.2073 9.31063 23.4933 10.0246 23.4933 10.9051C23.4933 11.7856 24.2073 12.4996 25.0878 12.4996C25.9683 12.4996 26.6823 11.7856 26.6823 10.9051C26.6823 10.0246 25.9683 9.31063 25.0878 9.31063ZM17.9928 11.1751C14.2233 11.1751 11.1678 14.2321 11.1678 18.0016C11.1678 21.7711 14.2233 24.8266 17.9928 24.8266C21.7623 24.8266 24.8163 21.7711 24.8163 18.0016C24.8163 14.2321 21.7623 11.1751 17.9928 11.1751ZM17.9928 13.5706C23.8503 13.5706 23.8578 22.4326 17.9928 22.4326C12.1368 22.4326 12.1278 13.5706 17.9928 13.5706Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1704_3670">
                      <rect width="36" height="36" rx="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
