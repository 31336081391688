import { observer } from 'mobx-react-lite'

const CustomTextField = observer((props:any) => {
  return (
    <div>
       <label className='block mb-2'>{props.label}{ props.mandatory ? (<span className="text-red-600 text-base font-normal">*</span>):("")}</label>
       <input 
         value={props.value}
         name={props.name}
         type="text"
         className={`border border-[#DBDBDB] ${props.height} rounded-md w-full text-[#4E5664] text-base px-4 bg-white `}
         onChange={props.handleChange}
       />
    </div>
  )
})

export default CustomTextField
