import { PaymentApi } from './../api/payment-api';
import { action, makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class PaymentStore {
  constructor(private paymentApi: PaymentApi) {
    makeAutoObservable(this);
    makePersistable(this, { name: 'PaymentStore', properties: [], storage: window.localStorage });
  }

  selectedPlanDetails: { selectedPlan: any, price: any } = {
    selectedPlan: '',
    price: null,
  };
  orderId: string = '';
  transactionDetails:object = {}
  paymentSlideValue:number = 0
  paymentCardsData:{}[]=[]
  paymentsliderMarks:object={}
  creditsAndPrices:{ credits: number, price: number,id:string }[]
  showPremiumPlandialog: boolean = false;
  ieltsWeb : boolean = false;
  ieltsMobile: boolean = false;
  response:[];
  responseMobile:[];
  paymentPlansMobile:any;
  @action
  async createOrder(payload) {
    return await this.paymentApi.createOrder(payload);
  }
@action
  async createOrder_2(payload) {
    return await this.paymentApi.createOrder_2(payload);
  }
@action
setResponse(payload){
  this.response = payload
}
@action setPaymentPlansMobile(mobile){
  this.paymentPlansMobile = mobile
}
@action
setResponsMobile(payload){
  this.responseMobile = payload
}
  @action
  async paymentSuccess(payload) {
    return await this.paymentApi.paymentSuccess(payload);
  }


  @action
  async setTransactionDetails(payload) {
    this.transactionDetails = payload
  }

  @action
  async setIeltsWeb(payload) {
    this.ieltsWeb = payload
  }

  @action
  async setIeltsMobile(payload) {
    this.ieltsMobile = payload
  }

  @action 
  async setPaymentSlider (payload){
    this.paymentSlideValue = payload
  }

  @action 
  async getPaymentsData(){
    return await this.paymentApi.getPaymentCardDetails();
  }

  @action 
  async setPaymentsCardsData(payload){
    this.paymentCardsData = payload
  }

  @action
  async setPaymentSliderMarks(payload){
    this.paymentsliderMarks = payload
  }

  @action
  async setCreditsAndPrices (payload){
    this.creditsAndPrices=payload
  }

  @action
  show_premium_dialog() {
    this.showPremiumPlandialog = true;
  }

  @action
  hide_premium_dialog() {
    this.showPremiumPlandialog = false;
  }

  

}
