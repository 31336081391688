export interface ApiConfig {
  url: string
  pingurl: string
  apiSecret: string
  timeout: number
}

const get_profile = () => {
  const host = window.location.host;
  if (host === 'www.staging.cambri.ai') return 'stage';
  if (host === 'staging.app.cambri.ai') return 'stage';
  if (host === 'cambri.ai') return 'prod';
  if (host === 'www.cambri.ai') return 'prod';
  if (host === 'app.cambri.ai') return 'prod';
  if (host === 'www.app.cambri.ai') return 'prod';
  if(host==='web.cambri.ai') return 'prod';
  if (host === 'localhost:3000') return 'stage';
  if (host === 'unknown:3000') return 'dev';
  return 'unknown';
};

let DOMAIN = 'api.cambri.ai';
const profile = get_profile();

switch (profile) {
  case 'dev':
    DOMAIN = 'localhost:8000'
    break;
  case 'stage':
    DOMAIN = 'dev.api.cambri.ai';
    break;

  case 'prod':
    DOMAIN = 'api.cambri.ai';
    break;

  default:
    DOMAIN = 'dev.api.cambri.ai';
    break;
}

//const DEV_DOMAIN = "192.168.29.196:8000"
//const PROD_DOMAIN = "api.cambri.ai"

//const DOMAIN = PROD_DOMAIN

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: `https://${DOMAIN}/api/v1/`,
  pingurl: ``,
  apiSecret: "eb5ece94-4c74-402e-8b35-53ea047bb208-5e49f1fa-9936-4d20-866c-1789ed866058",
  timeout: 100000
}
