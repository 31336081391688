

const useNavbar = (setColorchange) => {
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
          setColorchange(true);
        } else {
          setColorchange(false);
        }
      };
      window.addEventListener('scroll', changeNavbarColor);  
};

export default useNavbar;
