export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export const makeid_alpa_numeric = (length) => {
  var result = '';
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
      var randomIndex = getRandomInt(charactersLength);
      result += characters.charAt(randomIndex);
  }

  return result;
};

function getRandomInt(max) {
  var randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  return randomBuffer[0] % max;
}


const safe_get = (map_object, key, defaultValue) => {
  try {
    let current = map_object;
    const parts = key.split('.');
    for (const part of parts) {
      if (part.startsWith('$')) {

      } else {
        if (Object.keys(current).includes(part)) {
          current = current[part];
        } else {
          return defaultValue;
        }
      }
    }
    return current;
  } catch (e) {

  }

  return defaultValue;
};

export const fetch_articles_by_tag = async (tag) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');

  const response = await fetch(
    `https://blogs.cambri.ai/wp-json/wp/v2/posts?filter[tag]=${tag}&embed`,
    { method: 'GET', headers: headers, redirect: 'follow' }
  );

  const articles = await response.json();
  return articles;
};

export const fetch_articles_by_category_id = async (tag) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');

  const response = await fetch(
    `https://blogs.cambri.ai/wp-json/wp/v2/posts?categories=${tag}`,
    { method: 'GET', headers: headers, redirect: 'follow' }
  );

  const articles = await response.json();
  return articles;
};

export const get_featured_image = async (href) => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');

  const response = await fetch(href, { method: 'GET', headers: headers, redirect: 'follow' });

  const object = await response.json();
  const url = safe_get(object, 'media_details.sizes.full.source_url', undefined);
  return url;
};

export const fetch_all_articles = async () => {
  const headers = new Headers();
  headers.append('Authorization', 'Basic YXBpYWdlbnQ6aWlpamxuQXRBIyM5QV42KEdISnlFeWhx');
  const response = await fetch(
    `https://blogs.cambri.ai/wp-json/wp/v2/posts?embed`,
    { method: 'GET', headers: headers, redirect: 'follow' }
  );
  const articles = await response.json();
  return articles;
};
