import { observer } from 'mobx-react-lite';
import { TextareaHTMLAttributes } from 'react';

interface CustomTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const CustomTextArea = observer((props: CustomTextAreaProps) => {
  return (
    <div>
      <label className="block mb-2">{props.label}</label>
      <textarea
        {...props}
        className="border border-[#DBDBDB] rounded-md w-full px-4 py-5 bg-white text-[#4E5664] "
        onChange={props.handleChange}
      />
    </div>
  );
});

export default CustomTextArea;
