import React from 'react';

const SATPayments = () => {
  const cardsData = [
    {
      name: 'Basic Plan',
      price: '$10/month',
      points: ['Point 1', 'Point 2', 'Point 3'],
      buttonText: 'Select',
    },
    {
      name: 'Standard Plan',
      price: '$20/month',
      points: ['Point 1', 'Point 2', 'Point 3'],
      buttonText: 'Select',
    },
    {
      name: 'Premium Plan',
      price: '$30/month',
      points: ['Point 1', 'Point 2', 'Point 3'],
      buttonText: 'Select',
    },
  ];

  return (
<div className="flex flex-wrap justify-center space-x-4 p-4 mt-[8.5rem]">
      {cardsData.map((card, index) => (
        <div
          key={index}
          className="max-w-sm w-full lg:w-1/3 bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transform transition-all hover:scale-105"
        >
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4 text-blue-600">{card.name}</h2>
            <p className="text-gray-700 text-lg font-semibold mb-6">{card.price}</p>
            <ul className="mb-6">
              {card.points.map((point, idx) => (
                <li key={idx} className="text-gray-600 mb-2">
                  <svg className="inline w-4 h-4 mr-2 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 10-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                  </svg>
                  {point}
                </li>
              ))}
            </ul>
            <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition-all">
              {card.buttonText}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SATPayments;
