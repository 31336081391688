import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import './PhoneInput.css';
import { observer } from 'mobx-react-lite';
import CustomTextField from '../../components/customTextField';
import CustomTextArea from '../../components/customTextArea';
import { useStore } from '../../hooks/use-store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import NavMenu from '../home_page/navmenu';
import Footer from '../footer';

const Loader = () => (
  <div className="loader border-4 border-t-4 border-gray-200 border-t-blue-600 rounded-full w-6 h-6 animate-spin mx-auto"></div>
);

const BookaDemo = observer(() => {
  const navigate = useNavigate();
  const { userStore } = useStore();
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    product: '',
    message: '',
  });

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'email' && emailPattern.test(value)) {
      setEmailError('');
    } else if (name === 'email' && !emailPattern.test(value)) {
      setEmailError('Please enter valid email');
    } else {
      setEmailError('');
    }
  };

  const handleChangeMobileNumber = (value) => {
    setFormData({ ...formData, mobile: value });
    if (formData.mobile && isValidPhoneNumber(value)) {
      setMobileError('');
    } else if (formData.mobile && !isValidPhoneNumber('+' + value)) {
      setMobileError('Please Enter Valid Mobile Number');
    } else {
      setMobileError('');
    }
  };

  const aboutUs = localStorage.getItem('aboutUs');
  const ielts = localStorage.getItem('ielts');
  const sop = localStorage.getItem('sop');

  useEffect(() => {
    if (aboutUs === 'true') {
      setFormData({ ...formData, product: 'cambri' });
    } else if (ielts === 'true') {
      setFormData({ ...formData, product: 'ielts' });
    } else if (sop === 'true') {
      setFormData({ ...formData, product: 'sop' });
    } else {
      setFormData({ ...formData, product: 'cambri' });
    }
  }, []);
  const handleSubmit = async () => {
    if (
      formData.email !== '' &&
      formData.mobile !== '' &&
      formData.name !== '' &&
      emailPattern.test(formData.email) &&
      isValidPhoneNumber('+' + formData.mobile)
    ) {
      setIsLoading(true);
      await userStore.book_demo(formData);
  
      if (userStore.toast) {
        toast.success('Thank you for your interest, our team will reach out to you soon');
        setTimeout(() => {
          if (aboutUs === 'true') {
            navigate('/aboutus');
          } else if (ielts === 'true') {
            navigate('/ielts');
          } else if (sop === 'true') {
            navigate('/sop');
          } else {
            navigate('/');
          }
        }, 8000);
      } else {
        toast.error('Oops! Something Went Wrong');
      }
  
      setIsLoading(false);
    } else {
      toast.error('Please fill all fields');
    }
  };
  

  return (
    <div className="">
        <NavMenu />
      <div className="text-center mt-[12rem] text-2xl lg:text-2xl xl:text-3xl ">
        <span className="text-black font-bold">Don't just take our word for it,</span>
        <span className="text-[#A033FF] font-bold font-serif">See it in action.</span>
        <span className="text-black font-bold">Schedule your demo</span>
      </div>
      <div className="border-2 border-[#A033FF] bg-center bg-cover rounded-[50px] mt-[70px] py-10 px-10 md:px-28 mx-4 md:mx-32 ">
        <p className="text-black text-2xl lg:text-2xl xl:text-[2.5rem] font-[850] text-center">
          Please Enter Your Information
        </p>
        <div className="flex justify-around gap-x-20 flex-wrap lg:flex-nowrap">
        <div className="w-full mt-14 flex flex-col gap-y-10">
            <CustomTextField
              label="Your Name"
              value={formData.name}
              handleChange={onChangeHandler}
              name="name"
              height="h-[60px]"
              mandatory
            />
            <CustomTextField
              label="Email Address"
              value={formData.email}
              handleChange={onChangeHandler}
              name="email"
              height="h-[60px]"
              mandatory
            />
            <h1 className="text-red-600 text-base font-normal">{emailError}</h1>
          </div>
          <div className="w-full mt-[50px] flex flex-col gap-y-10">
            <div className="text-sm mb-1 text-black">
              {" "}
              Mobile Number
              <span className="text-red-600 text-base font-normal">*</span>
            </div>
            <div className="w-full">
              <PhoneInput
                containerClass="w-full -mt-[40px]"
                inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
                country={"in"}
                value={formData.mobile}
                onChange={handleChangeMobileNumber}
              />
            </div>
            <h1 className="text-red-600 text-base font-normal">
              {mobileError}
            </h1>
            <div className="-mt-[3.2rem]">
              <CustomTextArea
                label="Your Message"
                rows={8}
                value={formData.message}
                handleChange={onChangeHandler}
                name="message"
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-14">
          <button
            className={`rounded-[40px] font-extrabold lg:text-[28px] text-black ${
              formData.email === '' ||
              formData.mobile === '' ||
              formData.name === '' ||
              !emailPattern.test(formData.email) ||
              !isValidPhoneNumber('+' + formData.mobile)
                ? 'border border-[#A033FF]  text-black cursor-not-allowed'
                : 'bg-blue-600 text-white cursor-pointer'
            } py-2 px-4 w-full sm:w-[284px] h-10 lg:h-[64px] text-base leading-normal font-avenir mb-14 `}
            onClick={handleSubmit}
          >
            {isLoading ? <Loader /> : 'Submit'}
          </button>
        </div>
      </div>
      <Footer page={'bookademo'} />
    </div>
  );
});

export default BookaDemo;
